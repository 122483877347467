import React, { FC, Fragment } from "react";
import "devextreme/data/odata/store";
import {
  AppointmentDetail,
  CompanyNotesCard,
  OrderTotals,
} from "components/load-confirmation";
import { StopDetailsModel, Stop } from "types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";

type Props = {
  stopDetails: StopDetailsModel;
  loadNumber: string;
  stop: any;
};

const StopDetail: FC<Props> = ({ stopDetails, loadNumber, stop }) => {
  // Only display the first stop for the details.  We are passing in
  // an array because the Stops component expects one.  This will
  // allow code reuse instead of creating a new component.
  let singleStop: Stop[] = [];
  if (stopDetails) {
    var stops = stopDetails.stops;
    if (stops && stops.length > 0) {
      singleStop.push(stops[0]);
    }
  }

  return stopDetails && stopDetails.appointmentDetails ? (
    <Fragment>
      <AppointmentDetail
        cube={stopDetails.appointmentDetails.cube}
        stops={singleStop}
        pieceCount={stopDetails.appointmentDetails.pieceCount}
        lineItems={stopDetails.appointmentDetails.lineItems}
        weight={stopDetails.appointmentDetails.weight}
        loadNumber={loadNumber}
      />
      {stopDetails.stops.map((stop, index) => (
        <OrderTotals
          confirmationNumber={stop.order.confirmationNumber}
          orderNumber={stop.orderNumber}
          temperature={stop.order.temperature}
          key={stop.id}
          weight={stop.order.weight}
          cube={stop.order.cube}
          pieceCount={stop.order.pieceCount}
          items={stop.order.lineItems}
        />
      ))}
      {stop ? (
        <CompanyNotesCard stop={stop} />
      ) : (
        <div className="center-icon center red-component fas fa-spinner fa-spin" style={{marginTop: '25px'}}>
          <FontAwesomeIcon icon={faSpinner} size={"3x"} />
        </div>
      )}
    </Fragment>
  ) : (
    <Fragment />
  );
};

export default StopDetail;
